<template>
  <div>
    <!--begin::Events-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Roles</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <button
                type="button"
                class="mt-3 mr-1 mb-3 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span class="v-btn__content" @click="addRole()">
                  Add Role
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <v-data-table
          :headers="headers"
          :items="committee_roles"
          :search="search"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td> {{ item.name }} </td>
              <td> 
                  <template v-for="action in item.actions" >
                    <button
                      v-bind:key="action.action_name"
                      type="button"
                      class="mt-3 mr-1 mb-3 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
                    >
                      <span class="v-btn__content" @click="addTask(item.id, action.action_name)">
                        {{ action.action_name }}
                      </span>
                    </button>
                  </template>
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      fab
                      small
                      v-on="on"
                      @click="roleEdit(item.id)"
                    >
                      <v-icon color="#B5B5C3">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Role</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      fab
                      small
                      v-on="on"
                      @click="roleDelete(item.id)"
                    >
                      <v-icon color="#B5B5C3">mdi-delete-forever</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Role</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>
    <!--end::Events-->
  </div>
</template>

<script>
import Vuetify from "vuetify";
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import { DELETE_ROLE } from "@/core/services/store/committee.module";
import EventBus from "@/core/services/store/event-bus";

export default {
  name: "RoleList",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      committee_roles: [],
      search: "",
      headers: [
        { text: "Name", align: "left", value: "name", width: "25%" },
        { text: "Bind with", value: "description", width: "35%" },
        { text: "Action", sortable: false, value: "action", width: "20%" }
      ]
    };
  },
  mounted() {
    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Roles" }]);
    this.getCommitteeRoles();

    EventBus.$on("DELETE_ROLE", function(payLoad) {
      if (payLoad) {
        Swal.fire("Success", "Role deleted successfully.", "success");
      } else {
        Swal.fire("Error", "Error in deleting role.", "error");
      }
    });
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    addTask(role_id, task_type) {
      this.$router.push({
        name: "committee.roles.task.create",
        params: { type: task_type, role_id: role_id }
      });
    },
    roleDelete(id) {
      let context = this;
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this Roles details!",
        showCancelButton: true,
        confirmButtonText: "Delete"
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_ROLE, id).then(() => {
            this.getCommitteeRoles();
          });
        } else if (result.isDismissed) {
          Swal.fire("Training video is safe.", "", "info");
        }
      });
    },
    roleEdit(role_id) {
      this.$router.push({
        name: "committee.roles.edit",
        params: { id: role_id }
      });
    },
    getCommitteeRoles() {
      let context = this;
      axios({
        method: "get",
        url: "committee-roles",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.committee_roles = result.data.committee_roles;
        },
        function() {
          Swal.fire(
            "Error",
            "Error in fetching Committee roles list",
            "info"
          );
        }
      );
    },
    addRole() {
      let context = this;
      context.$router.push({ name: "committee.roles.add" });
    }
  }
};
</script>
